<main class="dashboard-main">
  <div class="__edit-deal" [ngClass]="{'loading-page': waiting}">
    <mat-tab-group
      mat-align-tabs="start"
      class="fullpage-tabs"
      disableRipple>
      <mat-tab label="General">
        <div class="p-40">
          <div class="card p-4 mb-4">
            <div class="row gx-3">
              <div class="col-lg-6">
                <div class="disabled-value disabled-value-img mb-3 mb-lg-0">
                  <img [src]="deal?.chainId | imgChainId" />
                  <div class="disabled-value__label">Currency</div>
                  <div class="disabled-value__value">{{deal?.paymentTokenSymbol}}</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="disabled-value disabled-value-img">
                  <img src="../../assets/images/network/bsc.svg" alt="">
                  <div class="disabled-value__label">Token Price</div>
                  <div class="disabled-value__value">{{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-8'}} {{paymentTokenSymbol}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row gx-4">
            <div class="col-lg-4">
              <div class="card p-4 mb-40">
                <div class="subtitle-1 text-break mb-3">Start Date (UTC)</div>
                <div class="small-text mb-3">
                  <div>Contract: {{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{startTimestamp}})</div>
                  <div>DB Time: {{deal?.startTime* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{deal?.startTime}})</div>
                </div>

                <mat-form-field appearance="fill" hideRequiredMarker>
                  <mat-label>Start Timestamp</mat-label>
                  <input matInput
                         type="number"
                         [(ngModel)]="startTimestamp"
                         name="startTimestamp"
                         required
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card p-4 mb-40">
                <div class="subtitle-1 text-break mb-3">End Date (UTC)</div>
                <div class="small-text mb-3">
                  <div>Contract: {{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{finishTimestamp}})</div>
                  <div>DB Time: {{deal?.finishTime* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{deal?.finishTime}})</div>
                </div>

                <mat-form-field appearance="fill" hideRequiredMarker>
                  <mat-label>End Timestamp</mat-label>
                  <input matInput
                         type="number"
                         [(ngModel)]="finishTimestamp"
                         name="finishTimestamp"
                         required
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card p-4 mb-40">
                <div class="subtitle-1 text-break mb-3">Token Distribution (UTC)</div>
                <div class="small-text mb-3">
                  <div>Contract: {{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{startClaimTimestamp}})</div>
                  <div>DB Time: {{deal?.startClaimTime* 1000 | date:'dd MMM, yyyy H:mm a'}}  (Timestamp: {{deal?.startClaimTime}})</div>
                </div>

                <mat-form-field appearance="fill" hideRequiredMarker>
                  <mat-label>Token Distribution</mat-label>
                  <input matInput
                         type="number"
                         [(ngModel)]="startClaimTimestamp"
                         name="startClaimTimestamp"
                         required
                  >
                </mat-form-field>
              </div>
            </div>

            <div class="card p-4 mb-40">
              <div class="btn-group-justified">
                <button type="button"
                        mat-raised-button
                        color="primary"
                        (click)="saveNewTimeClick()">
                  Save New Time
                </button>
                <button type="button"
                        mat-raised-button
                        color="primary"
                        (click)="syncTimeDb()">
                  Sync With Database
                </button>
              </div>
            </div>
          </div>

          <div class="row gx-4 mb-40">
            <div class="col-12 col-laptop-6 mb-4 mb-lg-0">
              <div class="card p-4">
                <div class="disabled-value disabled-value-img">
                  <img [src]="deal?.chainId | imgChainId" />
                  <div class="disabled-value__label">Min Raise</div>
                  <div class="disabled-value__value">{{minimumRaise| shiftDecimals:-paymentDecimal| number}} {{paymentTokenSymbol}}</div>
                </div>
              </div>
            </div>

            <div class="col-12 col-laptop-6">
              <div class="card p-4">
                <div class="form-group-inline __max-distributed">
                  <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
                    <i class="icon icomoon-wallet"></i>
                    <mat-label>Max Distributed Token {{rewardTokenSymbol}}</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="maxDistributedTokenAmount"
                           name="maxDistributedTokenAmount"
                    >
                  </mat-form-field>

                  <!-- TODO: Release_2.5 Add functionality on click -->
                  <button type="button"
                          class="short-btn"
                          mat-raised-button
                          color="primary"
                          (click)="saveMaxDistributedTokenAmountClick()">
                    Save
                  </button>
                  <button type="button"
                          class="short-btn"
                          mat-raised-button
                          color="primary"
                          (click)="syncMaxDistributedTokenAmountDb()">
                    DB Sync
                  </button>
                </div>
              </div>
            </div>
          </div>

          <form #updateRewardTokenForm="ngForm" (ngSubmit)="updateRewardAddress()" class="d-flex flex-column __deal-appearance">
          <div class="card p-4 mb-40">
            <div class="form-group-inline wrap-in-mob">
              <mat-form-field appearance="fill">
                <mat-label>Token Contract Address</mat-label>
                <input matInput [(ngModel)]="rewardToken" [disabled]="!isCollectWalletDeal" name="rewardToken">
              </mat-form-field>

              <button type="submit"
                      mat-raised-button
                      color="primary"
                      [disabled]="!updateRewardTokenForm.form.valid || !isCollectWalletDeal">
                Save
              </button>
            </div>
          </div>
          </form>

          <div class="card p-4 mb-40 d-flex align-items-center">
            <div class="subtitle-1 me-auto">Allow Refund</div>
            <div>{{allowRefund|YesNo}}</div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Allocation">

        <div class="p-40">
          <table class="tiers-table table w-100 mb-40">
            <thead>
              <tr>
                <th>#</th>
                <th>Tier</th>
                <th>BLP amount</th>
                <th>Ticket Size {{paymentTokenSymbol}}</th>
                <!-- <th>Allocation {{rewardTokenSymbol}}</th> -->
                <th *ngIf="!oldDeal">Fee Percent</th>
                <th>Purchased {{rewardTokenSymbol}}</th>
                <th>
                  <button *ngIf="!isPreview" class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base">
                    <img (click)="downloadData()" src="../../assets/svg/download-solid.svg"/>
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of allTiers; index as i;">
                <td title="#">
                  {{i+1}}
                </td>
                <td>
                  <img [src]="i | imgTierPipe" height="24" />
                </td>

                <td title="BLP amount" [ngClass]="{'edit-opened': i==updateTierIndex}">
                  <div [ngClass]="{'mb-3': i==updateTierIndex}" class="__tier-value">
                    {{item.blpAmount| shiftDecimals:-18}} BLP
                  </div>

                  <mat-form-field appearance="fill" *ngIf="i==updateTierIndex" hideRequiredMarker>
                    <mat-label>BLP amount</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="updateTierBlpAmount"
                           name="updateTierBlpAmount"
                           required>
                  </mat-form-field>
                </td>

                <td title="Ticket Size {{paymentTokenSymbol}}" [ngClass]="{'edit-opened': i==updateTierIndex}">
                  <div [ngClass]="{'mb-3': i==updateTierIndex}" class="__tier-value">
                    {{item.ticketSize| shiftDecimals:-paymentDecimal}}
                  </div>

                  <mat-form-field appearance="fill" *ngIf="i==updateTierIndex" hideRequiredMarker>
                    <mat-label>Ticket Size {{paymentTokenSymbol}}</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="updateTierTicketSize"
                           name="updateTierTicketSize"
                           required>
                  </mat-form-field>
                </td>

                <!-- <td title="Allocation {{rewardTokenSymbol}}" [ngClass]="{'edit-opened': i==updateTierIndex}">
                  <div [ngClass]="{'mb-3': i==updateTierIndex}" class="__tier-value">
                    {{item.allocation| shiftDecimals:-rewardDecimals}}
                  </div>
                  <mat-form-field appearance="fill" *ngIf="i==updateTierIndex" hideRequiredMarker>
                    <mat-label>Allocation {{rewardTokenSymbol}}</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="updateTierAllocation"
                           name="updateTierAllocation"
                           required>
                  </mat-form-field>
                </td> -->

                <td *ngIf="!oldDeal" title="Fee Percent" [ngClass]="{'edit-opened': i==updateTierIndex}">
                  <div [ngClass]="{'mb-3': i==updateTierIndex}" class="__tier-value">
                    {{percentFromInteger(item.feePercent)}}
                  </div>
                  <mat-form-field appearance="fill" *ngIf="i==updateTierIndex" hideRequiredMarker>
                    <mat-label>Fee Percent</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="updateTierFeePercent"
                           name="updateTierFeePercent"
                           required>
                  </mat-form-field>
                </td>

                <td title="Purchased {{rewardTokenSymbol}}">
                  <div [ngClass]="{'mb-3': i==updateTierIndex}" class="__tier-value">
                    {{item.purchasedTokens| shiftDecimals:-rewardDecimals}}
                  </div>

                  <div class="disabled-value" *ngIf="i==updateTierIndex">
                    <div class="disabled-value__label">Purchased</div>
                    <div class="disabled-value__value">{{item.purchasedTokens| shiftDecimals:-rewardDecimals}}</div>
                  </div>
                </td>

                <td>
                  <button *ngIf="i!=updateTierIndex"
                          mat-icon-button
                          [disabled]="waiting"
                          (click)="select(i)"
                          matTooltip="Edit Tier"
                          matTooltipPosition="above">
                    <span class="icomoon-edit"></span>
                  </button>

                  <button *ngIf="i==updateTierIndex"
                          mat-icon-button
                          [disabled]="waiting"
                          (click)="updateTierClick()"
                          matTooltip="Update Tier"
                          matTooltipPosition="above">
                    <span class="icomoon-checkmark"></span>
                  </button>
                </td>
              </tr>

              <tr>
                <td title="Index">
                  {{allTiers.length}}
                </td>
                <td title="Image">
                  <img [src]="allTiers.length | imgTierPipe" height="24" />
                </td>
                <td class="tiers-table__editable" title="BLP amount">
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>BLP amount</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="newTierBlpAmount"
                           name="newTierBlpAmount"
                           required>
                  </mat-form-field>
                </td>
                <td class="tiers-table__editable" title="Ticket Size">
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Ticket Size</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="newTierTicketSize"
                           name="newTierTicketSize"
                           required>
                  </mat-form-field>
                </td>
                <!-- <td class="tiers-table__editable" title="Allocation">
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Allocation</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="newTierAllocation"
                           name="newTierTicketSize"
                           required>
                  </mat-form-field>
                </td> -->
                <td *ngIf="!oldDeal" class="tiers-table__editable" title="Fee Percent">
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Fee Percent</mat-label>
                    <input matInput
                           type="number"
                           [(ngModel)]="newTierFeePercent"
                           name="newTierFeePercent"
                           required>
                  </mat-form-field>
                </td>
                <td></td>
                <td>
                  <button
                    mat-icon-button
                    [disabled]="waiting"
                    (click)="addTierClick()"
                    matTooltip="Add Tier"
                    matTooltipPosition="above">
                    <span class="icomoon-plus"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <form #updateVestingForm="ngForm" (ngSubmit)="createVestingClick()" *ngIf="!isCollectWalletDeal">
            <div class="card p-4">
              <div class="d-flex align-items-center mb-4">
                <div class="subtitle-1">Vesting</div>
                <a class="address-btn --link ms-3"
                   *ngIf="vestingContractAddress"
                   [href]="rewardToken | blockExplorerAddressPipe: deal?.chainId"
                   target="_blank">
                  {{vestingContractAddress}}
                </a>
              </div>

              <div class="alert alert-warning mb-4" role="alert">
                <div class="alert-icon icomoon-plumpy-info"></div>
                <div class="alert-heading">
                  You can change vesting only until {{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-lg-8">
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Vesting percent</mat-label>
                    <input matInput type="number" [(ngModel)]="vestingPercent" name="vestingPercent" required>
                  </mat-form-field>
                </div>
                <div class="col-lg-4">
                  <button type="button"
                          mat-raised-button
                          color="primary"
                          class="w-100"
                          [disabled]="waiting || now>startClaimTimestamp"
                          *ngIf="vestingContractAddress"
                          (click)="saveVestingPercentClick()">
                    Save vesting percent
                  </button>
                </div>
              </div>

              <div class="row mb-4">

                <div class="col-lg-4 mb-12">
                  <div class="mb-3 caption">Start Date {{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Start Date</mat-label>
                    <input matInput type="number" [(ngModel)]="vestingStart" name="vestingStart" required>
                  </mat-form-field>
                </div>

                <div class="col-lg-4 mb-12">
                  <div class="mb-3 caption">Current: {{vestingInterval|showPeriod}}</div>
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Interval in sec.</mat-label>
                    <input matInput type="number" [(ngModel)]="vestingInterval" name="vestingInterval" required>
                  </mat-form-field>
                </div>

                <div class="col-lg-4 mb-12">
                  <div class="mb-3 caption">Current: {{vestingDuration|showPeriod}}</div>
                  <mat-form-field appearance="fill" hideRequiredMarker>
                    <mat-label>Duration in sec.</mat-label>
                    <input matInput type="number" [(ngModel)]="vestingDuration" name="vestingDuration" required>
                  </mat-form-field>
                </div>
              </div>

              <button type="submit"
                      mat-raised-button
                      color="primary"
                      class="w-100 mb-4"
                      [disabled]="!updateVestingForm.form.valid || waiting|| now>startClaimTimestamp"
                      *ngIf="!vestingContractAddress">
                Create Vesting
              </button>

              <button type="button"
                      mat-raised-button
                      color="primary"
                      class="w-100"
                      [disabled]="waiting || now>startClaimTimestamp"
                      *ngIf="vestingContractAddress"
                      (click)="updateVestingClick()">
                Update Vesting
              </button>
            </div>
          </form>

          <div *ngIf="!oldDeal" class="row special-tier-percent">
            <div class="form-group-inline wrap-in-mob col-lg-6 col-laptop-6">
              <mat-form-field appearance="fill">
                <mat-label>Special Tiers Fee Percent</mat-label>
                <input matInput [(ngModel)]="specialTiersFeePercent" name="specialTiersFeePercent">
              </mat-form-field>
  
              <button type="button"
                      mat-raised-button
                      color="primary"
                      (click)="updateSpecialTiersFeePercent()">
                Save
              </button>
            </div>
          </div>
        </div>

        <div class="p-40" *ngIf="isPreview">
          <table class="tiers-table table w-100 mb-40">
            <thead>
              <tr>
                <th>#</th>
                <th>Tier</th>
                <th>Wallet Address</th>
                <th>Approval Date</th>
                <th>Amount</th>
                <th>
                  <button class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base">
                    <img (click)="downloadData()" src="../../assets/svg/download-solid.svg"/>
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of registrations; index as i;">
                <td title="#">
                  {{i+1}}
                </td>
                <td>
                  <img [src]="item.userTier | imgTierPipe" height="24" />
                </td>

                <td>
                  <div class="__tier-value">
                    {{item.userAddress}}
                  </div>
                </td>

                <td >
                  <div class="__tier-value">
                    {{item.creationDateTime + 'Z' | date :'YYYY-MM-dd HH:mm:ss'}}
                  </div>
                </td>

                <td >
                  <div  class="__tier-value">
                    {{item.amount}}
                  </div>
                </td>

                <td></td>
              </tr>
            </tbody>
          </table>

        </div>
      </mat-tab>

      <mat-tab label="Appearance">
        <form #updateDealForm="ngForm" (ngSubmit)="updateDealDb()" class="d-flex flex-column __deal-appearance">
          <div class="p-40">
            <div class="card p-4 mb-40">
              <div class="row align-items-center">
                <div class="col-lg-2 mb-2 mb-lg-0">
                  <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
                    <img src="{{targetNetwork | imgNetworkPipe}}"/>
                    <mat-label>Target Network</mat-label>
                    <mat-select disableRipple disableOptionCentering [(ngModel)]="targetNetwork" name="targetNetwork">
                      <mat-option *ngFor="let t of networkTypes" [value]="t.id">
                        <img src="{{t.id | imgNetworkPipe}}"/>
                        {{t.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2 mb-2 mb-lg-0">
                  <mat-form-field appearance="fill">
                    <mat-label>Deal Type</mat-label>
                    <mat-select disableRipple disableOptionCentering [(ngModel)]="dealType" name="dealType">
                      <mat-option *ngFor="let t of dealTypes" [value]="t.id">
                        {{t.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <div class="d-flex align-items-center">
                    <div class="subtitle-1 me-auto">Deal Visible for Users</div>
                    <mat-slide-toggle
                      name="visibleForUsers"
                      [(ngModel)]="visibleForUsers"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="d-flex align-items-center">
                    <div class="subtitle-1 me-auto">Hide TGE Date</div>
                    <mat-slide-toggle
                      name="hideTgeDate"
                      [(ngModel)]="hideTgeDate"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="d-flex align-items-center">
                    <div class="subtitle-1 me-auto">Preview</div>
                    <mat-slide-toggle
                      name="isPreview"
                      [(ngModel)]="isPreview"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-4 mb-40">
              <div class="subtitle-1 mb-3">Name And Description</div>

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="name" name="name" required>
              </mat-form-field>

              <div class="logo-upload mb-12">
                <mat-form-field appearance="fill" hideRequiredMarker class="logo-upload__input">
                  <mat-label>Deal Icon URL</mat-label>
                  <input matInput type="url" [(ngModel)]="logoURL" name="logoURL" required>
                </mat-form-field>

                <div class="logo-upload__preview">
                  <img [src]="logoURL?logoURL:'../../assets/images/no-image.svg'"/>
                </div>
              </div>

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Vesting Description</mat-label>
                <input matInput [(ngModel)]="emissionDescription" name="emissionDescription" required>
              </mat-form-field>

              <mat-form-field *ngIf="dealType == 1" appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Policy</mat-label>
                <input matInput [(ngModel)]="policy" name="policy">
              </mat-form-field>

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="description" name="description" rows="4" required></textarea>
              </mat-form-field>

              <!-- Token 1 -->
              <div class="row">
                <div class="col-3">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                    <mat-label>Custom Token Name</mat-label>
                    <input matInput [(ngModel)]="customTokenName" name="customTokenName">
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12" >
                    <mat-label>Custom Token Price</mat-label>
                    <input matInput [(ngModel)]="userFriendlyCustomPrice" name="userFriendlyCustomPrice">
                    <mat-hint>Token price is {{tokenPrice | shiftDecimals:-paymentDecimal| number: '1.0-8'}} </mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12" >
                    <mat-label>Investment Percentage</mat-label>
                    <input matInput [(ngModel)]="investmentPercentage" name="investmentPercentage">
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <div class="logo-upload mb-12">
                    <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                      <mat-label>Token Icon Url</mat-label>
                      <input matInput [(ngModel)]="tokenIconUrl" name="tokenIconUrl">
                    </mat-form-field>
                    <div class="logo-upload__preview">
                      <img [src]="tokenIconUrl?tokenIconUrl:'../../assets/images/no-image.svg'"/>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Token 2 -->
              <div class="row">
                <div class="col-3">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                    <mat-label>Custom Token Name</mat-label>
                    <input matInput [(ngModel)]="secondTokenCustomName" name="secondTokenCustomName">
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12" >
                    <mat-label>Custom Token Price</mat-label>
                    <input matInput [(ngModel)]="userFriendlySecondCustomPrice" name="userFriendlySecondCustomPrice">
                    <mat-hint>Token price is {{tokenPrice | shiftDecimals:-paymentDecimal| number: '1.0-8'}} </mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-12" >
                    <mat-label>Investment Percentage</mat-label>
                    <input matInput [(ngModel)]="secondTokenInvestmentPercentage" name="secondTokenInvestmentPercentage">
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <div class="logo-upload mb-12">
                    <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                      <mat-label>Token Icon Url</mat-label>
                      <input matInput [(ngModel)]="secondTokenIconUrl" name="secondTokenIconUrl">
                    </mat-form-field>
                    <div class="logo-upload__preview">
                      <img [src]="secondTokenIconUrl?secondTokenIconUrl:'../../assets/images/no-image.svg'"/>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end of tokens -->

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Fees</mat-label>
                <input matInput [(ngModel)]="fees" name="fees">
              </mat-form-field>

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>Project URL</mat-label>
                <input matInput type="url" [(ngModel)]="projectURL" name="projectURL" required>
              </mat-form-field>

              <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                <mat-label>CLaim URL</mat-label>
                <input matInput type="url" [(ngModel)]="claimURL" name="claimURL">
              </mat-form-field>

              <div class="row">
                <div class="col-lg-6">
                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/twitter.svg"/>
                    <mat-label>Twitter URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialTwitter" name="socialTwitter">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/medium.svg"/>
                    <mat-label>Medium URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialMedium" name="socialMedium">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/youtube.svg"/>
                    <mat-label>YouTube URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialYouTube" name="socialYouTube">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/facebook.svg"/>
                    <mat-label>Facebook URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialFacebook" name="socialFacebook">
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/telegram.svg"/>
                    <mat-label>Telegram URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialTelegram" name="socialTelegram">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/linkedIn.svg"/>
                    <mat-label>LinkedIn URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialLinkedIn" name="socialLinkedIn">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/instagram.svg"/>
                    <mat-label>Instagram URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialInstagram" name="socialInstagram">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="icon-form-field mb-12">
                    <img src="../../assets/images/social/telegram.svg"/>
                    <mat-label>Telegram URL with https:// (Optional)</mat-label>
                    <input matInput type="url" [(ngModel)]="socialTelegramChannel" name="socialTelegramChannel">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="d-flex disclaimer">
              <div class="subtitle-1">Show disclaimer</div>
              <mat-slide-toggle
                name="showDisclaimer"
                [(ngModel)]="showDisclaimer"
              >
              </mat-slide-toggle>
            </div>
            <div class="d-flex flex-column disclaimer-wrapper" *ngIf="showDisclaimer">
              <div class="quill-field">
                <mat-label>Disclaimer Text</mat-label>
                <ckeditor #modelRef="ngModel" [editor]="Editor" [config]="config" [(ngModel)]="disclaimerText" name="disclaimerText"></ckeditor>
                <!--<quill-editor [modules]="quillConfig" (onEditorCreated)="getEditorInstance($event)" placeholder="" [(ngModel)]="disclaimerText" name="disclaimerText"
                              [styles]="quillStyles" #quillEditor (onContentChanged)="addHttpsToLinks(quillEditor)" trackChanges="all">
                </quill-editor>-->
              </div>

              <mat-form-field appearance="fill" class="icon-form-field mb-12">
                <mat-label>Disclaimer (google doc url)</mat-label>
                <input matInput type="url" [(ngModel)]="disclaimer" name="disclaimer">
              </mat-form-field>
            </div>
           <!-- <iframe *ngIf="disclaimer" width="640" height="718" frameborder="0" marginheight="0" marginwidth="0"
                    src="https://docs.google.com/document/d/1N8v4RGziWVHfZjeu7CFZsFqPPY7uqe2n?embedded=true"
            >Loading…</iframe>-->

            <div class="form-save bg-white mt-auto">
              <button type="submit"
                      class="w-100"
                      mat-raised-button
                      color="primary"
                      [disabled]="!updateDealForm.form.valid || waiting">
                Save Appearance Settings
              </button>
            </div>
          </div>
        </form>

        <form #rewardTokenSymbolForm="ngForm" (ngSubmit)="UpdateRewardTokenSymbol()" class="__reward-token-form">
          <div class="card p-4 __reward-token">
            <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
              <mat-label>Reward Token Symbol</mat-label>
              <input matInput
                     [(ngModel)]="rewardTokenSymbol"
                     name="rewardTokenSymbol"
                     required>
            </mat-form-field>

            <button type="submit"
                    mat-raised-button
                    color="primary"
                    class="short-btn"
                    [disabled]="!rewardTokenSymbolForm.form.valid || waiting">
              Update Reward Token Symbol
            </button>
          </div>
        </form>

        <form *ngIf="dealType == 3" #minRewardTokenAmountForm="ngForm" (ngSubmit)="UpdateMinRewardTokenAmount()" class="__reward-token-form">
          <div class="card p-4 __reward-token">
            <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
              <mat-label>Minimal Reward Token Amount</mat-label>
              <input matInput
                     [(ngModel)]="minRewardTokenAmount"
                     name="minRewardTokenAmount"
                     required>
            </mat-form-field>

            <button type="submit"
                    mat-raised-button
                    color="primary"
                    class="short-btn"
                    [disabled]="!minRewardTokenAmountForm.form.valid || waiting">
              Update Token Amount
            </button>
          </div>
        </form>

      </mat-tab>

      <mat-tab label="Whitelisting">
        <form #updateWhitelistForm="ngForm" (ngSubmit)="updateWhitelist()" class="d-flex flex-column">
          <div class="p-40">
            <!--<div class="card p-4 mb-40">
              <div class="d-flex align-items-center">
                <div class="subtitle-1 me-auto">Whitelisting</div>
                <mat-slide-toggle
                  name="enabledWhiteList"
                  [(ngModel)]="enabledWhiteList"
                >
                </mat-slide-toggle>
              </div>
            </div>-->

            <div class="card p-4 mb-40">
              <div class="subtitle-1 mb-4">Whitelisting</div>

              <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-label>Whitelisted Addresses</mat-label>
                <textarea matInput [(ngModel)]="whiteListAddresses"
                          name="whiteListAddresses"
                          rows="15"
                          [disabled]="!enabledWhiteList">
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="form-save mt-auto bg-white">
            <button type="submit"
                    class="w-100"
                    mat-raised-button
                    color="primary"
                    [disabled]="!updateWhitelistForm.form.valid || waiting">
              Save Whitelist
            </button>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Custom Allocations">
        <form #customAllocationsForm="ngForm" class="d-flex flex-column">
          <div class="p-40">
            <div class="card p-4 mb-40">
              <div>
                To add Custom Allocations, enter data in CSV format (&lt;address&gt;,&lt;tier index from 0 to 5&gt;,&lt;Additional raise percent&gt;) and click Add<br />
                e.g. 0x0000000000000000000000000000000000000000,5,15
              </div>
              <div class="mt-2">
                To remove Custom Allocations, enter address list and click Remove<br />e.g. 0x0000000000000000000000000000000000000000
              </div>
              <mat-form-field appearance="fill" hideRequiredMarker>
                <textarea matInput [(ngModel)]="customAllocations"
                          name="customAllocations"
                          rows="15">
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="form-save mt-auto btn-group-justified">
            <button type="button"
                    class="w-100"
                    (click)="addCustomAllocations()"
                    mat-raised-button
                    color="primary"
                    [disabled]="!customAllocationsForm.form.valid || waiting">
              Add
            </button>
            <button type="button"
                    class="w-100"
                    (click)="removeCustomAllocations()"
                    mat-raised-button
                    color="primary"
                    [disabled]="!customAllocationsForm.form.valid || waiting">
              Remove
            </button>
          </div>
        </form>
      </mat-tab>


      <mat-tab label="Withdraw Funds">
        <div class="p-40">
          <div class="row">
            <div class="col-lg-6">
              <div class="card p-4">
                <button type="submit"
                        class="w-100 mb-12"
                        mat-raised-button
                        color="primary"
                        (click)="withdrawFundsClick()">
                  Withdraw funds
                </button>
                <button type="submit"
                        class="w-100"
                        mat-raised-button
                        color="primary"
                        (click)="withdrawNotSoldTokensClick()">
                  Withdraw not sold tokens
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Claiming">
        <div class="p-40">
          <div class="card p-4 console-log">
            <div class="subtitle-1">You can optionally specify the last block for deals finished long time ago</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Deal Last Block</mat-label>
              <input matInput [(ngModel)]="dealLastBlock" name="dealLastBlock" />
            </mat-form-field>
          </div>

          <div class="card p-4 mb-20">
            <button type="submit"
                    class="w-100"
                    mat-raised-button
                    color="primary"
                    [disabled]="isEventCollectionInProgress()"
                    (click)="extractDealPaymentEventsClick()">
              Extract Deal Payment Events
            </button>
          </div>

          <div class="card p-4 console-log">
            <div class="subtitle-1 mb-4">Console Log</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Events</mat-label>
              <textarea matInput [(ngModel)]="eventExtractionsStatus"
                        name="dealStatusEvents"
                        rows="5">
                ></textarea>
            </mat-form-field>
          </div>

          <div class="card p-4 mb-20">
            <button type="submit"
                    class="w-100"
                    mat-raised-button
                    color="primary"
                    (click)="showEventsExtractionStatus()">
              Get Event extraction status
            </button>
          </div>
          <div class="card p-4 mb-40">
            <div class="subtitle-1 mb-4">Deal Events</div>

            <div class="card p-4 mb-20">
              <button type="submit"
                      class="w-100"
                      mat-raised-button
                      color="primary"
                      (click)="showDealEventsClick()">
                Show Events
              </button>
            </div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Events</mat-label>
              <textarea matInput [(ngModel)]="dealEvents"
                        name="dealEvents"
                        rows="15">
                ></textarea>
            </mat-form-field>
          </div>

        </div>



      </mat-tab>
      <mat-tab label="Statistics">
        <div class="p-40">
          <table class="tiers-table table w-100 mb-40">
            <thead>
              <tr>
                <th>Time</th>
                <th><img [src]="-1 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="0 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="1 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="2 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="3 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="4 | imgTierPipe" class="tier-image"/> </th>
                <th><img [src]="5 | imgTierPipe" class="tier-image"/> </th>
                <th>Total </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let minutesAfterClaimStart of minutesAfterClaimTime; index as i;">
                <td>{{GetShortTimeStringFromMinute(minutesAfterClaimStart)}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(-1, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(0, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(1, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(2, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(3, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(4, minutesAfterClaimStart))}}</td>
                <td>{{formatNumber(GetPaymentTotalForTier(5, minutesAfterClaimStart))}}</td>
                <td>{{GetPaymentTotalPercentage(minutesAfterClaimStart)}}%</td>
              </tr>
            </tbody>
          </table>

          
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</main>
